import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/PostLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "hidden-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hidden-example",
        "aria-label": "hidden example permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Hidden example`}</h3>
    <p>{`Lorem ipsum, dolor sit amet.`}</p>
    <pre {...{
      "className": "night-owl-no-italics vscode-highlight",
      "data-language": "tsx"
    }}><code parentName="pre" {...{
        "className": "vscode-highlight-code"
      }}><span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk6"
          }}>{`/** `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`@jsx`}</span><span parentName="span" {...{
            "className": "mtk6"
          }}>{` jsx */`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { graphql, Link, useStaticQuery } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`gatsby`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { Themed `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`as`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` s, jsx } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`theme-ui`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { BlogPostsQuery } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`./__generated__/BlogPostsQuery`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { Header, Root, theme } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`../components`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { BlogpostDetails } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`../components/BlogpostDetails`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`import`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` { Seo } `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`from`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`../components/Seo`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`IndexPage`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` = `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`()`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` => {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  const { `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`allMdx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` } = `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`useStaticQuery`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk12"
          }}>{`BlogPostsQuery`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`graphql`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`\``}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    query `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`BlogPostsQuery`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`allMdx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`nodes`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`frontmatter`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`title`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`spoiler`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`date`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`fields`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`route`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`readingTime`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`          }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`        }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`      }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    }`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`\``}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  return `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Root`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Seo`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`titleTemplate`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`%s`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Header`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` />`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.h1`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`sx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{ mt: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`3`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`, mb: `}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`4`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`haspar.us`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.h1`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.p`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        Howdy! I'm Piotr Monwid-Olechnowicz and this is my personal blog.`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.p`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<main>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        `}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`allMdx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk43"
          }}>{`nodes`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`map`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`(`}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk44"
          }}>{`i`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=>`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` {`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const { `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`frontmatter`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`fields`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` } = `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`node`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`!`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`const { `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`title`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`spoiler`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`, `}</span><span parentName="span" {...{
            "className": "mtk7"
          }}>{`date`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` } = `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`frontmatter`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{` || {}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`return`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` (`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<article `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`key`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`i`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`              `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<header>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.h3`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`sx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{ marginBottom: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`0.4375rem`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`, color: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`text`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{` }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Link`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`to`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`fields`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`!.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`route`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`!`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`sx`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`{`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                      ...`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`theme`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk43"
          }}>{`styles`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`a,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                      color: `}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk18"
          }}>{`currentColor`}</span><span parentName="span" {...{
            "className": "mtk36"
          }}>{`"`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`,`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                    }`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                  >`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                    `}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`title`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                  `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Link`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.h3`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`                `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`BlogpostDetails`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`date`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`date`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                  `}</span><span parentName="span" {...{
            "className": "mtk5"
          }}>{`timeToRead`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`=`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`fields`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`!.`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`readingTime`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk9"
          }}>{`                />`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`              `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</header>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`              `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`<`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.p`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`{`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`spoiler`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`s.p`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`            `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</article>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`          );`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`        })`}</span><span parentName="span" {...{
            "className": "mtk24"
          }}>{`}`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`      `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</main>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}>{`    `}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`</`}</span><span parentName="span" {...{
            "className": "mtk8"
          }}>{`Root`}</span><span parentName="span" {...{
            "className": "mtk9"
          }}>{`>`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`  `}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`)`}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`}`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{`;`}</span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk1"
          }}></span></span>{`
`}<span parentName="code" {...{
          "className": "vscode-highlight-line"
        }}><span parentName="span" {...{
            "className": "mtk3"
          }}>{`export`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` `}</span><span parentName="span" {...{
            "className": "mtk3"
          }}>{`default`}</span><span parentName="span" {...{
            "className": "mtk1"
          }}>{` IndexPage;`}</span></span></code></pre>
    <p>{`Text below is taken from `}<a parentName="p" {...{
        "href": "/refinement-types"
      }}>{`/refinement-types`}</a>{` blogpost`}</p>
    <h2 {...{
      "id": "how-can-i-use-them-what-will-i-gain",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-can-i-use-them-what-will-i-gain",
        "aria-label": "how can i use them what will i gain permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`How can I use them? What will I gain?`}</h2>
    <p>{`I’ve refined the `}<strong parentName="p"><em parentName="strong">{`number`}</em></strong>{` into `}<strong parentName="p"><em parentName="strong">{`Even`}</em></strong>{` during my
`}<a parentName="p" {...{
        "href": "https://github.com/hasparus/refinement-types-in-typescript"
      }}>{`Wrocław TypeScript talk`}</a>{`.`}<br parentName="p"></br>{`
`}{`Having a type for even numbers doesn’t sound really useful but the idea stays
the same for real world types with complex names copied from Jira.`}</p>
    <p><em parentName="p">{`We can call the predicate once and if it’s true, we remember this fact in
the type system to avoid accidental errors.`}</em></p>
    <h3 {...{
      "id": "example-time",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#example-time",
        "aria-label": "example time permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Example time`}</h3>
    <h4 {...{
      "id": "almost-real-example",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#almost-real-example",
        "aria-label": "almost real example permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Almost real example`}</h4>
    <p>{`Assume we’re building a form in which users can enter a list of emails into
a textarea to send activation links to give their friends write permissions
for the app (let’s say it’s a CMS).`}</p>
    <p>{`We validate these emails once, and if they’re all valid, we save them, and
later, few steps further in the form, we send our activation links. Few days
later, we get a new requirement — the user should be able to edit the data
at the last step of the form. Cool, the form is pretty long, we understand
that it can be useful. Several lines of code and we’re done. We go home
happy about the good code we pushed. Next morning we see a new issue in the
tracker — `}<em parentName="p">{`“Validate emails after the user does final edits”`}</em>{` — We totally
forgot about the validation.`}</p>
    <h4 {...{
      "id": "how-could-we-save-the-day-with-a-refinement-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#how-could-we-save-the-day-with-a-refinement-type",
        "aria-label": "how could we save the day with a refinement type permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`How could we save the day with a refinement type?`}</h4>
    <ul>
      <li parentName="ul">{`Create a subtype of `}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{` called `}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{`, such that
`}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{` is not assignable to `}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{`.`}</li>
      <li parentName="ul">{`Hold already validated emails in a list of type `}<strong parentName="li"><em parentName="strong">{`ValidEmails`}</em></strong>{`.`}</li>
      <li parentName="ul">{`Now you can’t push a string to a list of already validated emails ✨`}</li>
      <li parentName="ul">{`Change the type of `}<inlineCode parentName="li">{`sendEmail`}</inlineCode>{` function from `}<inlineCode parentName="li">{`(email: string) => void*`}</inlineCode>{` to
`}<inlineCode parentName="li">{`(email: ValidEmail) => void`}</inlineCode>{`. `}<br parentName="li"></br>
        {`It doesn’t make sense to send an email to `}<inlineCode parentName="li">{`"🦄🐵💣"`}</inlineCode>{` which is a perfectly valid
string.`}</li>
    </ul>
    <p>{`( `}{`*`}{` ) or IO, Result, choose your favorite.`}</p>
    <h4 {...{
      "id": "yeah-right-but-how-can-i-create-this-validemail-type",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#yeah-right-but-how-can-i-create-this-validemail-type",
        "aria-label": "yeah right but how can i create this validemail type permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Yeah right, but how can I create this “ValidEmail” type?`}</h4>
    <p>{`However you want! It’s just an idea from type theory and you can implement
it in your favorite way. Few ideas:`}</p>
    <ul>
      <li parentName="ul">{`you can go full OOP and extend a `}<strong parentName="li"><em parentName="strong">{`String`}</em></strong>{`,`}</li>
      <li parentName="ul">{`use `}<a parentName="li" {...{
          "href": "https://github.com/hasparus/nom-ts"
        }}>{`nominal`}</a>{` `}
        <a parentName="li" {...{
          "href": "https://michalzalecki.com/nominal-typing-in-typescript/#approach-4-intersection-types-and-brands"
        }}>{`typing`}</a>{`
and leave no runtime trail `}<em parentName="li">{`(my favorite option)`}</em>{`,`}</li>
      <li parentName="ul">{`put the string into a
`}<a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Value_object"
        }}>{`value object`}</a>{`, because
`}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{` doesn’t even have to be a subtype of `}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{`. `}<br parentName="li"></br>
        {`The key is that `}<strong parentName="li"><em parentName="strong">{`string`}</em></strong>{` is not assignable to `}<strong parentName="li"><em parentName="strong">{`ValidEmail`}</em></strong>{`, because
we want to ensure validation.`}</li>
    </ul>
    <h3 {...{
      "id": "user-defined-type-guards",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#user-defined-type-guards",
        "aria-label": "user defined type guards permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`User defined type guards`}</h3>
    <p>{`We can use TypeScript’s
`}<a parentName="p" {...{
        "href": "https://www.typescriptlang.org/docs/handbook/advanced-types.html#using-type-predicates"
      }}>{`user defined type guards`}</a>{`
to tell the compiler that our predicate checks the type and this is exactly
what we’re interested in when we talk about refinements.`}</p>
    <p>{`Let’s empower our `}<inlineCode parentName="p">{`isValidEmail`}</inlineCode>{` predicate by changing its signature from
`}<inlineCode parentName="p">{`(s: string) => boolean`}</inlineCode>{` to `}<inlineCode parentName="p">{`(s: string) => s is ValidEmail`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "takeaways",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#takeaways",
        "aria-label": "takeaways permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Takeaways`}</h2>
    <ul>
      <li parentName="ul">{`Refinements are not implemented in TypeScript, but you can make them in
userspace.`}</li>
      <li parentName="ul">{`You can use nominal typing to make sure your refinements have no runtime
trail (except predicate checks).`}</li>
      <li parentName="ul">{`You can use them to encode facts about your data in the type system.`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "further-reading",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#further-reading",
        "aria-label": "further reading permalink",
        "className": "remark-autolink-headers__anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "viewBox": "0 0 24 24",
          "fill": "none",
          "stroke": "currentColor",
          "strokeWidth": "2"
        }}><path parentName="svg" {...{
            "d": "M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"
          }}></path><path parentName="svg" {...{
            "d": "M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"
          }}></path></svg></a>{`Further reading`}</h2>
    <p><em parentName="p">{`This is mostly a reading list for future me, but I hope you can also find
it interesting.`}</em></p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "https://github.com/fthomas/refined"
          }}>{`refined`}</a>{` for Scala sounds really
interesting and with `}<a parentName="p" {...{
            "href": "https://www.scala-js.org/"
          }}>{`ScalaJs`}</a>{` I could target
the same platforms as TypeScript.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`”`}<a parentName="p" {...{
            "href": "http://kmcallister.github.io/talks/rust/2015-dependent-types/slides.html"
          }}>{`A taste of dependent types`}</a>{`”
by Keegan McAllister`}</p>
      </li>
    </ul>

    <style {...{
      "className": "vscode-highlight-styles"
    }}>{`
  
  .night-owl-no-italics {
background-color: #011627;
color: #d6deeb;
}

.night-owl-no-italics .mtk1 { color: #D6DEEB; }
.night-owl-no-italics .mtk2 { color: #011627; }
.night-owl-no-italics .mtk3 { color: #C792EA; }
.night-owl-no-italics .mtk4 { color: #82B1FF; }
.night-owl-no-italics .mtk5 { color: #ADDB67; }
.night-owl-no-italics .mtk6 { color: #637777; }
.night-owl-no-italics .mtk7 { color: #82AAFF; }
.night-owl-no-italics .mtk8 { color: #F78C6C; }
.night-owl-no-italics .mtk9 { color: #7FDBCA; }
.night-owl-no-italics .mtk10 { color: #FF5874; }
.night-owl-no-italics .mtk11 { color: #FFEB95; }
.night-owl-no-italics .mtk12 { color: #FFCB8B; }
.night-owl-no-italics .mtk13 { color: #7FDBCAFF; }
.night-owl-no-italics .mtk14 { color: #80CBC4; }
.night-owl-no-italics .mtk15 { color: #FF6363; }
.night-owl-no-italics .mtk16 { color: #57EAF1; }
.night-owl-no-italics .mtk17 { color: #5F7E97; }
.night-owl-no-italics .mtk18 { color: #ECC48D; }
.night-owl-no-italics .mtk19 { color: #B2CCD6; }
.night-owl-no-italics .mtk20 { color: #FAD430; }
.night-owl-no-italics .mtk21 { color: #FFFFFF; }
.night-owl-no-italics .mtk22 { color: #FF2C83; }
.night-owl-no-italics .mtk23 { color: #020E14; }
.night-owl-no-italics .mtk24 { color: #D3423E; }
.night-owl-no-italics .mtk25 { color: #EC5F67; }
.night-owl-no-italics .mtk26 { color: #8BD649; }
.night-owl-no-italics .mtk27 { color: #5CA7E4; }
.night-owl-no-italics .mtk28 { color: #A2BFFC; }
.night-owl-no-italics .mtk29 { color: #EF535090; }
.night-owl-no-italics .mtk30 { color: #ADDB67FF; }
.night-owl-no-italics .mtk31 { color: #697098; }
.night-owl-no-italics .mtk32 { color: #FF869A; }
.night-owl-no-italics .mtk33 { color: #DDDDDD; }
.night-owl-no-italics .mtk34 { color: #E0DEC6; }
.night-owl-no-italics .mtk35 { color: #CDEBF7; }
.night-owl-no-italics .mtk36 { color: #D9F5DD; }
.night-owl-no-italics .mtk37 { color: #6AE9F0; }
.night-owl-no-italics .mtk38 { color: #C789D6; }
.night-owl-no-italics .mtk39 { color: #BEC5D4; }
.night-owl-no-italics .mtk40 { color: #31E1EB; }
.night-owl-no-italics .mtk41 { color: #8EACE3; }
.night-owl-no-italics .mtk42 { color: #78CCF0; }
.night-owl-no-italics .mtk43 { color: #FAF39F; }
.night-owl-no-italics .mtk44 { color: #D7DBE0; }
.night-owl-no-italics .mtk45 { color: #7986E7; }
.night-owl-no-italics .mtki { font-style: italic; }
.night-owl-no-italics .mtkb { font-weight: bold; }
.night-owl-no-italics .mtku { text-decoration: underline; text-underline-position: under; }
`}</style>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      